const PRODUCT_PREFIX = 'SUSHIRO_';
export default {
  EDMODO_WEB_APP_STATE    : 'edmodo-web-app-state',
  CONFIG_MODE             : 'config_mode',
  CLEARED                 : 'cleared',
  LAST_LANGUAGE           : 'lastLanguage',
  NEW_TEACHER_ONBOARDING  : 'newTeacherOnboarding',
  TRANSLATOR_MODE_ON      : 'translatorModeOn',
  LAST_PAGE_VISITED       : 'lastPageVisited',
  FIRST_TIME_USER_EXP     : 'firstTimeUserExperience',
  USER_SELECTED_LANGUAGE  : 'userSelectedLanguage',
  ORDER_DETAIL            : `${PRODUCT_PREFIX}orderDetail`,
  SUBMIT_ORDER            : `${PRODUCT_PREFIX}submitOrder`,
  ST_PAYMENT_INTENT       : `${PRODUCT_PREFIX}st_payment_intent`,
  LAST_PAYMENT_INFO       : `${PRODUCT_PREFIX}last_payment_info`,
  FB_AD                   : `${PRODUCT_PREFIX}fb_ad`,
  FB_FBCLID               : `${PRODUCT_PREFIX}fbclid`,
  FB_ENTRY_TIMESTAMP      : `${PRODUCT_PREFIX}entry_timestamp`,
  DRIVER_ORDER_ID         : `${PRODUCT_PREFIX}driver_order_id`,
  DRIVER_ORDER_NU         : `${PRODUCT_PREFIX}driver_order_order`,
  SELECTED_TRIAL_PRODUCT  : `${PRODUCT_PREFIX}selected_trial_product`,
  SHARE_CLICK_NUM         : `${PRODUCT_PREFIX}share_click_num`,
  SHARE_COUPON_KEY        : `${PRODUCT_PREFIX}share_coupon_key`,
  SHARE_PROGRESS          : `${PRODUCT_PREFIX}share_progress`,
  PURCHASE_EVENT          : `${PRODUCT_PREFIX}purchase_event`,

  PIXEL_ID                : `${PRODUCT_PREFIX}pixel_id`,
  FBQ_TOKEN               : `${PRODUCT_PREFIX}fbq_token`,
  TG_ID                   : `${PRODUCT_PREFIX}telegram_id`,
  PAYMENT_AMOUNT          : `${PRODUCT_PREFIX}payment_amount`,
  PAYMENT_AMOUNT_FAST     : `${PRODUCT_PREFIX}payment_amount_fast`,
  PURCHASE_NUM            : `${PRODUCT_PREFIX}purchase_num`,

  RETRY_PAYMENT_TIME      : `${PRODUCT_PREFIX}retry_payment_time`,

}
