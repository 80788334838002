import React, { Component } from 'react';
import queryString from "query-string";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SushiBrandIcon from '../../icons/SushiBrandIcon';
import SushiDecorationIcon from '../../icons/SushiDecorationIcon';
import SushiDesktopBg from '../../images/sushi/desktop.jpg';
import SushiBg1 from '../../images/sushi/bg-1.jpg';
import SushiBg2 from '../../images/sushi/bg-2.png';
import RdsBackIcon from '../../icons/RdsBackIcon';
import FingerIcon from '../../icons/FingerIcon';
import Slideshow from '../../images/sushi/slideshow.png';
import Slideshow2 from '../../images/sushi/slideshow2.png';
import RDSButton from '../../components/common/RDSButton';
import * as imgActionCreator from '../../actions/imgActionCreator';
import * as orderActionCreator from '../../actions/orderActionCreator';
import * as globalAlertActionCreator from '../../actions/globalAlertActionCreator';
import * as fbEventActionCreator from '../../actions/fbEventActionCreator';
import * as TranslationSelectors from '../../selectors/TranslationSelectors';
import Loading from '../../components/common/LoadingIndicator';

import classNames from 'classnames';
import Coupon_15000 from '../../images/sushi/coupon/coupon-15000.jpg';
import Coupon_30000 from '../../images/sushi/coupon/coupon-30000.jpg';
import PagePathConstants from '../../constants/router/PagePathConstants';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import tracker from '../../tracker';
import * as FBUtil from '../../utils/FbUtil';
import CookieUtil from '../../utils/CookieUtil';
import { generateRandomString } from '../../utils/RandomGeneratorUtils';
import { sendCustomEvent } from '../../apis/ConfigAPI';
import ShareModalFireworksIcon from '../../icons/ShareModalFireworksIcon';
import FireworksIcon from '../../icons/FireworksIcon';
import FireworksBackgroundImg from '../../images/sahua.gif';
import XIcon from '../../icons/XIcon';
import icon_LINE from '../../images/scoial/icon_LINE.png';
import { CountUp } from 'countup.js';
import Footer from '../../components/common/footer';

const HTTP_REQUEST_URL = window.location.protocol + "//" + window.location.host + '/?ad=share';
const VEHICLE_MODEL = {
    '15000': {
        key: '15000',
        amount: '5,000',
        num: 3,
        title: '15,000円引き',
        useCondition: '購入金額に関係なく使えます！どの商品にも使えます！',
        applyTitle: `5,000円券×3枚 -- 本日残り${Math.floor(Math.random() * (300 - 200 + 1)) + 200}枚！`,
        disabled: false,
        img: Coupon_15000,
        trialTime: Math.floor(Math.random() * (300 - 200 + 1)) + 200,
        originAmount: '15,000',
        shareAmount: CookieUtil.loadPaymentAmount() ? parseInt(CookieUtil.loadPaymentAmount()) : 310,
    },
    '30000': {
        key: '30000',
        amount: '5,000',
        num: 6,
        title: '30,000 円引き',
        useCondition: '購入金額に関係なく使えます！どの商品にも使えます！',
        applyTitle: '5,000円券×6枚 -- クーポンは完売しました！',
        disabled: true,
        img: Coupon_30000,
        trialTime: Math.floor(Math.random() * (300 - 200 + 1)) + 200,
        originAmount: '30,000',
        shareAmount: CookieUtil.loadPaymentAmountFast() ? parseInt(CookieUtil.loadPaymentAmountFast()) : 1000,
    }
};

class VehicleModelSelectorPage extends Component {
    constructor(props, context) {
        super(props, context);
        const cacheTrialProduct = CookieUtil.loadTrialProduct();
        const cacheClickNum = CookieUtil.loadClickNum();
        let selectedVehicleModel = Object.assign({}, VEHICLE_MODEL['15000']);
        if (cacheTrialProduct && cacheTrialProduct.hasOwnProperty(VEHICLE_MODEL['15000'].key)) {
            selectedVehicleModel.trialTime = cacheTrialProduct[VEHICLE_MODEL['15000'].key];
        }
        this.state = {
            selectedVehicleModel: selectedVehicleModel,
            showCongratulationModal: false,
            showCouponDetailModal: false,
            viewCouponKey: null,
            clickNum: cacheClickNum,
        };

        // this.handleSelectVehicleModel = this.handleSelectVehicleModel.bind(this);
        this.goBack = this.goBack.bind(this);
        this.prefillVehicleModel = this.prefillVehicleModel.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.saveVehicleModalTrialTime = this.saveVehicleModalTrialTime.bind(this);
        this.handleViewCoupon = this.handleViewCoupon.bind(this);
        this.toggleCongratulationModal = this.toggleCongratulationModal.bind(this);
        this.toggleCouponDetailModal = this.toggleCouponDetailModal.bind(this);
        this.handleShareEvent = this.handleShareEvent.bind(this);
    }

    componentDidMount() {
        // const cacheClickNum = CookieUtil.loadClickNum();
        // const cachedCouponKey = CookieUtil.loadCouponKey();
        // if (cacheClickNum && cachedCouponKey && cacheClickNum !== 0) {
        //     this.toggleCongratulationModal();
        // }

        this.saveVehicleModalTrialTime();
        // this.prefillVehicleModel();
    }

    handleShareEvent(couponKey) {
        let { clickNum } = this.state;
        clickNum = clickNum + 1;
        CookieUtil.saveClickNum(clickNum);
        CookieUtil.saveCouponKey(couponKey);
        const data = {
            id: '',
            orderNu: '',
            shareSuccess: 1,
            clickShareNum: clickNum
        }
        const orderId = LocalStorageUtil.loadOrderId();
        if (orderId && orderId !== '' && orderId !== 'undefined') {
            data.id = orderId;
        }
        const orderNu = LocalStorageUtil.loadOrderNumber();
        if (orderNu && orderNu !== '' && orderNu !== 'undefined') {
            data.orderNu = orderNu;
        }

        sendCustomEvent(`Sushiro-Share-${clickNum}`);
        // orderActions && orderActions.updateOrderShareStatus({data: JSON.stringify(data)})
        // .catch(() => {});

        const invitation_text = `スシロー5,000円クーポン×6枚を限定配布中！`;
        const shareUrl = `https://line.me/R/share?text=${encodeURIComponent(invitation_text + " " + HTTP_REQUEST_URL)}`;
        window.open(shareUrl, "_blank");   // 打开新窗口
        setTimeout(() => {
            this.toggleCongratulationModal();
        }, 2000);
    }

    saveVehicleModalTrialTime() {
        const cacheTrialProduct = CookieUtil.loadTrialProduct();
        if (!cacheTrialProduct) {
            const cacheData = {
                [this.state.selectedVehicleModel.key]: this.state.selectedVehicleModel.trialTime
            }
            CookieUtil.saveTrialProduct(cacheData);
        }
    }

    prefillVehicleModel() {
        const { location } = this.props;
        const orderInfo = LocalStorageUtil.loadOrderDetail();
        const queryParams = queryString.parse(location.search);
        let selectedVehicleModel;
        if (queryParams.vehicleModel) {
            selectedVehicleModel = VEHICLE_MODEL.filter(item => item.key === queryParams.vehicleModel)[0];
        }

        this.setState({ selectedVehicleModel: selectedVehicleModel ? selectedVehicleModel : this.state.selectedVehicleModel });
    }

    // handleSelectVehicleModel(model) {
    //     const { fbEventActions } = this.props;
    //     const { selectedVehicleModel } = this.state;
    //     if (model.disabled || selectedVehicleModel.key === model.key) return;

    //     const eventId = generateRandomString(10);
    //     const eventParams = { selectedSku: model };
    //     sendCustomEvent('CustomizeProduct');
    //     FBUtil.trackFBEvent('CustomizeProduct', eventParams, eventId);
    //     fbEventActions && fbEventActions.submitFBConversionEvent('CustomizeProduct', eventParams, null, eventId, null);

    //     let cacheTrialProduct = CookieUtil.loadTrialProduct();
    //     if (cacheTrialProduct && cacheTrialProduct.hasOwnProperty(model.key)) {
    //         // 需要从缓存中取使用次数，并且递减一点
    //         let latestTrialNum;
    //         let end = cacheTrialProduct[model.key];
    //         if (end < 10) {
    //             latestTrialNum = Math.floor(Math.random() * (end - 1 + 1)) + 1;
    //             model.trialTime = latestTrialNum;
    //             this.setState({ selectedVehicleModel: model });
    //             cacheTrialProduct[model.key] = latestTrialNum;
    //             CookieUtil.saveTrialProduct(cacheTrialProduct);
    //             return;
    //         }
    //         let start = cacheTrialProduct[model.key] - 10;
    //         latestTrialNum = Math.floor(Math.random() * (end - start + 1)) + start;
    //         model.trialTime = latestTrialNum;
    //         cacheTrialProduct[model.key] = latestTrialNum;
    //     } else {
    //         if (cacheTrialProduct) {
    //             cacheTrialProduct[model.key] = model.trialTime;
    //         } else {
    //             cacheTrialProduct = {};
    //             cacheTrialProduct[model.key] = model.trialTime;
    //         }
    //     }
    //     CookieUtil.saveTrialProduct(cacheTrialProduct);
    //     this.setState({ selectedVehicleModel: model });
    // }

    goBack() {
        const { history } = this.props;
        history && history.goBack();
    }

    handleNextPage() {
        const { selectedVehicleModel } = this.state;
        const { history, location, fbEventActions } = this.props;
        let orderInfo = { vehicleModel: selectedVehicleModel.key };
        tracker.trackVehicleModel(selectedVehicleModel.key);
        LocalStorageUtil.saveOrderDetail(orderInfo);

        const eventParams = {
            content_ids: 'sushiro-coupon',
            content_type: 'product',
            contents: [{
                id: 'sushiro-coupon-15000',
                title: 'Sushiro-Coupon',
                quantity: 1
            }],
            currency: 'JPY',
            value: CookieUtil.loadPaymentAmount() || 310
        };

        const eventId = generateRandomString(10);
        sendCustomEvent('AddToCart');
        FBUtil.trackFBEvent('AddToCart', eventParams, eventId);
        fbEventActions && fbEventActions.submitFBConversionEvent('AddToCart', eventParams, null, eventId, null);


        setTimeout(() => {
            history && history.push({ pathname: PagePathConstants.CUSTOM_INFO, search: location.search });
        }, 500);
    }

    handleViewCoupon(couponKey) {
        if (VEHICLE_MODEL[couponKey].disabled) return;
        const { fbEventActions } = this.props;
        const eventId = generateRandomString(10);
        const eventParams = { selectedSku: couponKey };
        CookieUtil.saveCouponKey(couponKey);
        sendCustomEvent('CustomizeProduct');
        FBUtil.trackFBEvent('CustomizeProduct', eventParams, eventId);
        fbEventActions && fbEventActions.submitFBConversionEvent('CustomizeProduct', eventParams, null, eventId, null);

        this.setState({ viewCouponKey: couponKey }, () => {
            this.toggleCouponDetailModal();
        });
    }

    toggleCongratulationModal() {
        this.setState({
            showCongratulationModal: !this.state.showCongratulationModal,
            showCouponDetailModal: false
        }, () => {
            setTimeout(() => {
                if (this.state.showCongratulationModal) {
                    const cachedCouponKey = CookieUtil.loadCouponKey();
                    const countUp = new CountUp('coupon-current-price', VEHICLE_MODEL[cachedCouponKey].shareAmount);
                    countUp.start();
                }
            }, 1000);
        });
    }

    toggleCouponDetailModal() {
        this.setState({ showCouponDetailModal: !this.state.showCouponDetailModal })
    }

    render() {
        const { showLoading, selectedVehicleModel, showCongratulationModal, showCouponDetailModal, viewCouponKey } = this.state;
        let translations = this.props.translations && this.props.translations['submission_page'];
        let trialTimeText = `本日残りのクーポンは${selectedVehicleModel.trialTime}枚です`;
        const cachedCouponKey = CookieUtil.loadCouponKey();
        const shareSuccessText = `受け取り完了！<span class="coupon-amount">${cachedCouponKey ? VEHICLE_MODEL[cachedCouponKey].amount : '5,000'}円クーポン×${cachedCouponKey ? VEHICLE_MODEL[cachedCouponKey].num : 1}枚</span>を無事に受け取りました！送料<span id="coupon-current-price">${cachedCouponKey ? VEHICLE_MODEL[cachedCouponKey].originAmount : '8000'}</span>円で手に入る！情報を入力してお支払いください！`;
        return (
            <div className='tw-w-full tw-h-full gotham-font tw-text-[#393c41] tw-bg-[#f2f2f2]'>
                {showLoading && <Loading />}
                {showCongratulationModal && <div className='tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-bg-[rgba(0,0,0,0.6)] tw-flex tw-items-center tw-justify-center tw-z-[1000]'>
                    <div className='tw-rounded-[15px] tw-w-[500px] phone:tw-w-[340px] animate__animated animate__bounceInDown tw-bg-white tw-px-[15px] tw-py-[15px] tw-relative tw-z-[1001]' style={{ boxShadow: '2px 6px 19px 11px rgba(0,0,0,.125)' }}>
                        {/* <ShareModalFireworksIcon size={110} className="tw-absolute tw-left-[-20px] tw-top-[-37px]" style={{ transform: 'rotate(342deg)' }} /> */}
                        <div className='tw-mt-[10px] tw-w-full !tw-z-[1003] tw-relative tw-flex tw-flex-col tw-items-center tw-justify-center'>
                            <div className='tw-w-full tw-flex tw-justify-end'><XIcon color='#333' size={36} onClick={this.toggleCongratulationModal} /></div>
                            <div className='tw-text-brand-primary tw-text-[20px] tw-font-bold tw-text-center'>受け取り成功！</div>
                            <div className='tw-mt-[20px] tw-text-center tw-text-[14px]' dangerouslySetInnerHTML={{ __html: shareSuccessText }}>{ }</div>

                            <div className='tw-px-[20px] tw-py-[26px] tw-bg-[#f2f2f2] tw-rounded-[15px] tw-w-[280px] tw-h-[280px] tw-flex tw-justify-center tw-items-center' style={{ backgroundImage: `url(${SushiBg2})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                <img src={cachedCouponKey && VEHICLE_MODEL[cachedCouponKey].img} alt='coupon' className='tw-mx-auto tw-w-[180px] tw-h-[180px]' />
                            </div>

                            <div className='tw-w-full tw-my-[30px] tw-border-top tw-border-[2px] tw-border-border-gray tw-border-dashed'></div>
                            <div
                                className="tw-bg-black tw-text-center tw-py-[8px] tw-w-[320px] phone:!tw-w-full tw-rounded-[6px] tw-text-white tw-text-[14px]"
                                onClick={this.handleNextPage}>
                                <span>必要な情報をご入力ください</span>
                            </div>
                        </div>
                        <FireworksIcon size="300" className="tw-absolute tw-top-0 tw-left-0 tw-rounded-t-[30px] !tw-z-[1002]" />
                    </div>
                </div>}
                {showCouponDetailModal && <div className='tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-bg-[rgba(0,0,0,0.6)] tw-flex tw-items-center tw-justify-center tw-z-[1000]'>
                    <div className='tw-rounded-[15px] tw-w-[500px] phone:tw-w-[300px] animate__animated animate__bounceInDown tw-relative tw-z-[1001] tw-overflow-hidden' style={{ boxShadow: '2px 6px 19px 11px rgba(0,0,0,.125)' }}>
                        {/* <ShareModalFireworksIcon size={110} className="tw-absolute tw-left-[-20px] tw-top-[-37px]" style={{ transform: 'rotate(342deg)' }} /> */}
                        <div className='tw-mt-[0px] tw-w-full tw-bg-white tw-px-[15px] tw-py-[15px] !tw-z-[1003] tw-relative'>
                            {/* <div className='tw-w-full tw-flex tw-justify-end tw-absolute tw-top-[15px] tw-right-[15px]'></div> */}

                            <div className='tw-pl-[8px]'>
                                <div className='tw-flex tw-justify-between tw-items-center'>
                                    <SushiBrandIcon size={50} />
                                    <XIcon color='#333' size={28} onClick={this.toggleCouponDetailModal} />
                                </div>
                                <div className='tw-font-bold tw-text-[16px] tw-mt-[22px]'>スシロー  {viewCouponKey && VEHICLE_MODEL[viewCouponKey].amount}円クーポン×{viewCouponKey && VEHICLE_MODEL[viewCouponKey].num}枚</div>
                                <div className='tw-text-[12px] tw-mt-[12px] tw-flex tw-items-center'><div className='tw-mr-[4px]'>※</div>{viewCouponKey && VEHICLE_MODEL[viewCouponKey].useCondition}</div>
                                <div className='tw-text-[12px] tw-mt-[8px] tw-flex tw-items-center'><div className='tw-mr-[4px]'>※</div>お受け取り後、7～15営業日以内にご自宅まで郵送いたします！</div>
                            </div>
                            <div className='tw-my-[30px] tw-border-top tw-border-[2px] tw-border-border-gray tw-border-dashed tw-relative'>
                                <div className='tw-w-[30px] tw-h-[30px] tw-rounded-full tw-bg-[#585858] tw-z-[100000] tw-absolute tw-left-[-32px] tw-top-[-15px]'></div>
                                <div className='tw-w-[30px] tw-h-[30px] tw-rounded-full tw-bg-[#585858] tw-z-[100000] tw-absolute tw-right-[-32px] tw-top-[-15px]'></div>
                            </div>


                            <div className='tw-px-[20px] tw-py-[26px] tw-bg-[#f2f2f2] tw-rounded-[15px]' style={{ backgroundImage: `url(${SushiBg2})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                <img src={viewCouponKey && VEHICLE_MODEL[viewCouponKey].img} alt='coupon' className='tw-mx-auto tw-mt-[20px] tw-w-[180px] tw-h-[180px]' />
                            </div>

                            {viewCouponKey && <div className='tw-w-[310px] tw-mx-auto phone:tw-w-full tw-mt-[20px] tw-text-[12px] tw-rounded-[5px] tw-pl-[0px] tw-py-[5px] tw-pr-[4px] tw-bg-brand-primary tw-text-white tw-font-bold tw-cursor-pointer hs-ad-apply-btn tw-flex tw-items-center tw-justify-center' onClick={this.toggleCongratulationModal}>
                                <img src={icon_LINE} alt='share' className='tw-w-[30px] tw-h-[30px] phone:tw-hidden' />
                                <span>クリックして無料クーポンをゲット！</span>
                            </div>}
                        </div>
                        {/* <FireworksIcon size="300" className="tw-absolute tw-top-0 tw-left-0 tw-rounded-t-[30px] !tw-z-[1002]" /> */}
                    </div>
                </div>}
                <div className='tw-w-full tw-h-full phone:tw-h-full tw-overflow-scroll'>
                    <div className='tw-z-[100] tw-w-[660px] tw-mx-auto phone:tw-w-full tw-h-[60px] tw-flex tw-justify-between tw-items-center tw-shadow-xl tw-bg-white'>
                        <div className='tw-w-1/3 tw-h-full tw-flex tw-items-center phone:tw-pl-[20px]'><RdsBackIcon color='#B81C22' onClick={this.goBack} /></div>
                        <SushiBrandIcon size={45} className="tw-w-auto" />
                        <div className='tw-w-1/3'></div>
                    </div>
                    <div className='tw-mt-[66px] tw-w-[660px] tw-mx-auto  phone:tw-w-full phone:tw-mt-[16px] tw-overflow-hidden'>
                        <div className='tw-text-[28px] phone:tw-leading-[32px] phone:tw-leading-[28px] tw-font-[500] phone:tw-text-[18px] tw-relative tw-px-[30px]'>
                            <SushiDecorationIcon size={16} className="tw-absolute tw-left-[30px] tw-top-[4px]" />
                            <p className='tw-indent-[26px]'>{translations && translations.title}</p>
                        </div>
                        <div className='tw-h-[240px] tw-relative' style={{ backgroundImage: `url(${SushiDesktopBg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                            <div className='tw-w-[6270px] tw-h-[130px] sushi-menu-container tw-absolute tw-top-[-32px]'>
                                <img src={Slideshow} alt='sushi' className='tw-h-[130px] !tw-w-[2090px] sushi-menu' />
                                <img src={Slideshow} alt='sushi' className='tw-h-[130px] !tw-w-[2090px] sushi-menu' />
                                <img src={Slideshow} alt='sushi' className='tw-h-[130px] !tw-w-[2090px] sushi-menu' />
                            </div>
                            <div className='tw-w-full tw-h-[96px] tw-bottom-0 tw-absolute tw-px-[30px]' >
                                <div className='tw-w-full tw-h-[96px] tw-rounded-t-[10px] tw-border-[4px] tw-border-[#c97c59] tw-border-b-0' style={{ backgroundImage: `url(${SushiBg1})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                    <p className='tw-text-[16px] phone:tw-leading-[30px] tw-font-[800] tw-flex tw-items-center tw-justify-center tw-text-[14px] tw-mt-[20px] phone:tw-w-[300px] phone:tw-mx-auto'><FingerIcon size={46} style={{ transform: 'rotate(180deg)' }} />スシローのクーポンが無料で手に入ります！</p>
                                </div>
                            </div>
                        </div>


                        <div className='tw-px-[30px]'>
                            <div className='phone:tw-text-[12px] tw-mt-[15px] tw-text-[18px]'>{translations && translations.test_drive_description}</div>

                            <div className='tw-mt-[15px] tw-w-full phone:tw-h-auto tw-flex phone:tw-flex-wrap tw-justify-center tw-items-center'>
                                <div className='tw-w-[360px] phone:tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-between tw-items-center'>
                                    {Object.keys(VEHICLE_MODEL).map((key) => {
                                        const item = VEHICLE_MODEL[key];
                                        return (<div className='tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-mb-[30px]'>
                                            <div className='tw-w-[340px] tw-h-[340px] tw-pb-[6px] tw-flex tw-items-center tw-justify-center' style={{ backgroundImage: `url(${SushiBg2})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                                <div onClick={() => this.handleViewCoupon(item.key)} className={classNames('tw-w-[220px] tw-h-[220px] tw-relative', { 'coupon-img-disabled': item.disabled })} style={{ backgroundImage: `url(${item.img})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                                                    <div className={classNames('tw-w-[220px] tw-h-[220px] tw-absolute tw-left-0 tw-top-0', { 'tw-bg-[rgba(0,0,0,0.3)] coupon-img-disabled': item.disabled })}>
                                                        <div className='tw-absolute tw-bottom-0 tw-w-full tw-text-white tw-text-[13px] tw-py-[8px] tw-text-center tw-bg-[rgba(0,0,0,0.6)]'>{item.applyTitle}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {!item.disabled && <div className='tw-text-[12px] tw-rounded-[5px] tw-py-[8px] tw-px-[30px] tw-bg-brand-primary tw-text-white tw-font-bold tw-cursor-pointer hs-ad-apply-btn tw-flex tw-items-center tw-justify-center' onClick={() => this.handleViewCoupon(item.key)}>
                                                {/* <img src={icon_LINE} alt='share' className='tw-w-[40px] tw-h-[40px]' /> */}
                                                <span>クリックして無料クーポンをゲット！</span>
                                            </div>}

                                        </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tw-w-[660px] phone:tw-w-full tw-overflow-hidden tw-mx-auto'>
                        <div className='tw-w-[2580px] tw-h-[70px] sushi-emojo-container '>
                            <img src={Slideshow2} alt='sushi' className='tw-h-[70px] !tw-w-[860px] sushi-menu' />
                            <img src={Slideshow2} alt='sushi' className='tw-h-[70px] !tw-w-[860px] sushi-menu' />
                            <img src={Slideshow2} alt='sushi' className='tw-h-[70px] !tw-w-[860px] sushi-menu' />
                        </div>
                    </div>
                    <Footer classProps="tw-mx-auto" />
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        translations: TranslationSelectors.selectTranslations(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        imgActions: bindActionCreators(imgActionCreator, dispatch),
        orderActions: bindActionCreators(orderActionCreator, dispatch),
        globalAlertActions: bindActionCreators(globalAlertActionCreator, dispatch),
        fbEventActions: bindActionCreators(fbEventActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleModelSelectorPage);
