import React, { Component } from 'react';
import FooterLogo from '../../../images/sushi/logo-2.png';
import FooterLogo2 from '../../../images/sushi/footer-1.png';
import SushiBrandIcon from '../../../icons/SushiBrandIcon';

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { specifiedClass, classProps, style } = this.props;
    return (<div className={'tw-w-full tw-flex tw-justify-center  tw-text-brand-primary tw-mt-[70px] tw-mx-auto' + classProps}  >
      <div className='tw-w-[660px] phone:tw-w-full tw-bg-white' style={{ borderTop: '2px dashed #ccc' }}>
        <div className='tw-w-full tw-h-[145px] tw-bg-brand-primary tw-text-white tw-relative tw-py-[20px] tw-px-[10px] tw-flex tw-items-center'>
          <div className='tw-w-[50%] tw-flex tw-flex-col tw-items-center tw-justify-center'>
            <img src={FooterLogo} alt='logo' className='tw-w-[56px] tw-h-[56px]' />
            <div className='tw-text-[12px] tw-mt-[10px]'>スシローをもっとお得に</div>
            <div className='tw-text-[14px] tw-font-bold'>スシロー公式アプリ</div>
          </div>
          <div className='tw-w-[50%] tw-h-full tw-relative'>
            <img src={FooterLogo2} alt='logo' className='tw-w-[189px] tw-h-[209px] tw-absolute tw-bottom-[-20px] tw-right-[-10px]' />
          </div>

        </div>
        <div className='tw-my-[20px] tw-flex tw-items-center tw-flex-wrap tw-text-[12px] tw-text-black  tw-px-[20px]'>
          <div>お問い合わせ</div>
          <div className='tw-mx-[20px]'>|</div>
          <div>会社案内</div>
          <div className='tw-mx-[20px]'>|</div>
          <div>サイトポリシー</div>
          <div className='tw-mx-[20px]'>|</div>
          <div>個人情報の保護に関する基本方針</div>
        </div>

        <div className='tw-mt-[20px] tw-text-[10px] tw-px-[20px]'>
          <div className={'tw-my-[20px] tw-text-[10px] tw-pb-[0px] tw-flex tw-items-center tw-justify-between tw-text-black ' + specifiedClass}>
            <div className='tw-ml-6px]'>©AKINDO SUSHIRO CO.,LTD.ALL RIGHTS</div>
            <SushiBrandIcon size={30} />
          </div>
        </div>
      </div>
    </div>);
  }
}

export default Footer;
