

import React from 'react';
import Colors from '../constants/ColorConstants';

const SushiBrandDecorationIcon = ({
    size = 24,
    color = '#221815',
    viewBox = '0 0 24 24',
    ...props
}) => (
    <svg width={size} height={1.45 * size} viewBox='0 0 18 26' {...props}>
  <defs>
    <clipPath id="a">
      <path fill="#b81c22" d="M0 0h10v10H0z"/>
    </clipPath>
    <clipPath id="b">
      <path fill="#b81c22" d="M0 0h8v8H0z"/>
    </clipPath>
  </defs>
  <g>
    <g style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
      <g>
        <g clipPath="url(#a)" transform="translate(8 8)">
          <path fill="#b81c22" d="M0 0h10v10H0z"/>
        </g>
      </g>
    </g>
    <g style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
      <g>
        <g clipPath="url(#b)" transform="translate(0 18)">
          <path fill="#b81c22" d="M0 0h8v8H0z"/>
        </g>
      </g>
    </g>
    <g style={{mixBlendMode: 'multiply', isolation: 'isolate'}}>
      <g>
        <g clipPath="url(#b)">
          <path fill="#b81c22" d="M0 0h8v8H0z"/>
        </g>
      </g>
    </g>
  </g>
</svg>
);

export default SushiBrandDecorationIcon;
