export const BASE_URL = window.location.protocol + "//" + window.location.host;
// export const STORY_BASE_URL = (window.location.protocol + "//" + window.location.host).replace('www.','story.');

export const STORY_BASE_URL = 'https://story.sushi-jp.com';


const Endpoint = {
    POST_TO_ADDRESS: '/api/index/address',
    ME: '/user/getUserinfo',
    
    STORY_DETAIL: '/index.php/api/Tiezi/getTiezi',
    STORY_COMMENT_LIST: '/index.php/api/Pinglun/getPinglunlisttiezi',

    UPLOAD: '/api/common/upload',
    ORDER: '/api/index/index',
    ORDER_DETAIL: '/api/index/getOrderInfoByOrderNo',
    ORDER_UPDATE: '/api/index/updateOrder',
    ORDER_STATUS_UPDATE: '/api/index/customstatus',
    ORDER_SHARE_STATUS: '/api/index/updateOrderShareStatus',
    TRANSLATION: '/api/Urlrelation/getUrlrelationUrl',
    PIXEL: '/api/Urlrelation/getUrlrelationUrl',
    FB_CONVERSION_EVENT: '/api/facebook/EventsAll',
    CUSTOM_EVENT: '/api/Events/addevent',
}
export default Endpoint;

export const DEFAULT_PIXEL_ID = '454888524306542';
export const DEFAULT_FBQ_TOKEN = 'EAAQkAJNepdoBO9B2doeZB554zyeWdxUKfB8mefUsi28xdgbRu8EesDlilZAwncSza4FYReyeWUQP0hJvVhIWm4Q9TSukHwGhvak4mMwKVDglPxz7EVv6H4dGxJwUrIRQJopm13qTPfMOL4vNZCGZARpmW5GPmMOmWrQJbuyxzty3kUGhb2Eb0OU9lnZBNHIMkLwZDZD';
